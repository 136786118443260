<template>
  <div v-if="loading" class="card-body py-5">
    <DotLoader :type="'pulse'"></DotLoader>
  </div>
  <div v-else-if="!loading && error" class="container">
    <div class="card mb-5 text-first border-0">
      <div class="card-body max-800 mx-auto col-12">
        <h3 class="mb-4 font-30 color-33 text-clamp-2 form-title">{{ $t("register.default.title") }}</h3>
        <p class="font-16 color-33 text-clamp-2">{{ $t("register.default.expired") }}</p>
      </div>
    </div>
  </div>
  <div v-else-if="!loading" class="container">
    <div class="card mb-5 text-first border-0">
      <div class="card-body max-800 mx-auto col-12">
        <h3 class="mb-4 font-30 color-33 text-clamp-2 form-title">{{ $t("register.default.title") }}</h3>
        <p class="font-20 color-33 text-clamp-2">{{ tournamentInfo.tournamentName }}</p>
        <div class="row name">
          <div class="col m-t-15">
            <label class="m-b-10" for="lastName" v-html="$t('register.default.labelLastName')"></label>
            <input v-model="lastName" class="form-control" type="text">
          </div>
          <div class="col m-t-15">
            <label class="m-b-10" for="firstName" v-html="$t('register.default.labelFirstName')"></label>
            <input v-model="firstName" class="form-control" type="text">
          </div>
        </div>
        <div class="row">
          <div v-show="errors['lastName']" id="err_lastName" class="form-error">{{ errors['lastName'] }}</div>
          <div v-show="errors['firstName']" id="err_firstName" class="form-error">{{ errors['firstName'] }}</div>
        </div>

        <div class="row">
          <div class="col m-t-15">
            <label class="m-b-10" for="mail">{{ $t("account.editWithPassword.labelEmail") }}</label>
            <input v-model="email" class="form-control" name="mail" type="text">
          </div>
        </div>
        <div class="row">
          <div v-show="errors['email']" id="err_mail" class="form-error">{{ errors['email'] }}</div>
        </div>

        <div class="row dob-section mt-3">
          <label class="m-b-10" for="birthdayYear">{{ $t("account.editWithPassword.labelBirthday") }}</label>
          <div class="d-inline-flex">
            <div class="input-group custom-select-year parent-block">
              <select id="birthdayYear" v-model="year" class="form-control select_year form-select">
                <option v-for="year in years"
                        :value="year">{{ year }}
                </option>
                <option selected value="">-</option>
                <option v-for="year in years1"
                        :value="year">{{ year }}
                </option>
              </select>
            </div>
            <div class="input-group custom-select-year parent-block">
              <select id="birthdayMonth" v-model="month" class="form-control select_month form-select">
                <option selected value="">-</option>
                <option v-for="month in months"
                        :value="month">{{ month }}
                </option>
              </select>
            </div>
            <div class="input-group custom-select-year parent-block m-r-0">
              <select id="birthdayDate" v-model="date" class="form-control select_date form-select">
                <option selected value="">-</option>
                <option v-for="day in days"
                        :value="day">{{ day }}
                </option>
              </select>
            </div>
          </div>
          <div v-show="errors['birthday']" id="err_inputBirth" class="form-error">{{ errors['birthday'] }}</div>
        </div>

        <!--
        <div class="row mt-3">
          <label class="m-b-10" for="role">{{ $t("register.default.role") }}</label>
          <div class="mx-auto">
            <select v-model="roleId" class="form-control form-select citizenship">
              <option v-for="obj in roleList"
                      :value="obj.roleId">{{ obj.roleName }}
              </option>
            </select>
          </div>
          <div v-show="errors['userRoleId']" id="err_userRoleId" class="form-error">{{ errors['userRoleId'] }}</div>
        </div>
        -->

        <div class="row">
          <div class="col m-t-15">
            <label class="m-b-10" for="organization">{{ $t("account.editWithoutPassword.inputOrganization") }}</label>
            <input v-model="organization" class="form-control" type="text">
          </div>
          <div v-show="errors['organization']" id="err_organization" class="form-error">{{ errors['organization'] }}</div>
        </div>

        <div class="row m-t-20">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-1 order-sm-0">
            <button
                class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none mt-2 light border-0 custom-btn"
                @click="$router.push({ name: 'Login' })">
              {{ $t("account.editWithPassword.btnCancel") }}
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-2 custom-btn"
                    @click="registerUser">
              {{ $t("firstTime.btnRegistration") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Common from '../assets/jsipclient/common'
import {RoleItems, SetRegister} from '../assets/jsipclient/index'
import DotLoader from '@/components/loaders/DotLoader'
import {mapGetters} from "vuex"
import Helper from "../assets/jsipclient/helper"

export default {
  name: "RegisterUser",
  components: {
    DotLoader
  },
  data() {
    return {
      errors: [],
      key: '',
      firstName: '',
      lastName: '',
      email: '',
      loading: true,
      roleList: [],
      roleId: 2,
      organization: '',
      error: false,
      tournamentInfo: '',
      years: Helper.getRange(1920, 1989, 4),
      years1: Helper.getRange(1990, (new Date).getFullYear(), 4),
      months: Helper.getRange(1, 12),
      days: Helper.getRange(1, 31),
      year: '',
      month: '',
      date: '',
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getRoles()
    }
  },
  mounted() {
    this.formKeyGet()
    this.getRoles()
  },
  methods: {
    formKeyGet() {
      this.errors = [];
      const api = Common.createLoginApi()
      this.key = this.$route.params.key
      api.loginRegisterFormKeyGetAsync(this.key)
          .then((response) => {
            this.loading = false
            this.tournamentInfo = response
          })
          .catch((error) => {
            this.loading = false
            this.error = true
          })
    },
    registerUser() {
      this.errors = [];
      const api = Common.createLoginApi()
      const setRegister = new SetRegister()
      setRegister.tournamentKey = this.key
      setRegister.firstName = this.firstName
      setRegister.lastName = this.lastName
      setRegister.email = this.email
      setRegister.birthday = `${this.year}/${this.month}/${this.date}`
      setRegister.userRoleId = 9 // Default
      setRegister.organization = this.organization
      api.loginRegisterPostAsync(setRegister)
          .then(() => {
            const message = this.$t("register.default.toastSuccessRegistration", {
              tournament: this.tournamentInfo.tournamentName
            })

            Common.showToast(message)
            this.$router.push({name: 'Login'})
          })
          .catch((error) => {
            Common.getErrorList(error.response, this.errors)
          })
    },
    async getRoles() {
      try {
        const api = new IpApi.SystemApi(Helper.apiConfig(this.config.token))
        const jsonObject = await api.systemRolesGetAsync(0)
        const result = RoleItems.createFromJson(jsonObject)
        this.roleList = result.items

      } catch (error) {
          Common.handleAPIError(error)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.error {
  margin-top: 20px;
}

.form-title {
  word-break: break-word !important;
}

</style>